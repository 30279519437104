%molecule-pagination{    
    width: 100%;
	text-align: center;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	.pagin-item{
		display: inline-block;
		color: inherit;
		margin-left: 15px;

		&:before{
			display: none !important;
		}

		a{
			position: relative; 
			z-index: 2;
		}

		&.pagin-prev{
			margin-right: auto;
			margin-left: 0;
		}

		&.disabled{
			pointer-events: none;
		}
		&.is-active{
			pointer-events: none;
		}
		&.pagin-next{
			margin-left: auto;
		} 
		&.pagin-first{
			margin-left: 0;
		} 

		/**
			Pagination "chiffres"
		*/
		&:not(.pagin-next):not(.pagin-prev):not(.pagin-first):not(.pagin-last){
			position: relative;
			& > a, & > div{
				text-decoration: none;
			}
			.btn-text{
				font-family: $main-font;
				font-size: 1.5rem;
				color: $grey-color-dark;
				font-weight: 600;
				padding: 3px;
			} 
			&.is-active:after{
				content: '';
				display: block;
				position: absolute;
				bottom: 5px;
				height: 2px;
				width: 100%;
				background-color: $core-color;
			}
			a:hover, a:focus{
				background-color: $core-color;
				.btn-text{
					color: #FFFFFF;
				}
			}
		}
		/**
			Pagination "Boutons"
		*/
		&.pagin-next,
		&.pagin-prev,
		&.pagin-first,
		&.pagin-last{
			&:not(.is-active):not(.disabled){			
				& > div, & > a{
					@extend %atom-btn-int--pill;
					text-decoration: none;
				}
			}
			&.is-active{
				& > div, & > a{
					@extend %atom-btn-int--pill; 
					text-decoration: none;
				}
			} 
			&.disabled{
				opacity: 0;
				& > div, & > a{
					@extend %atom-btn-int--pill; 
					text-decoration: none;
				}
			}				
		}
		&.pagin-prev{
			& > div, & > a{
				@include RWD(desktop){
					padding: 0 60px 0 90px !important;					
				}
				@include RWD(tablet_only){
					padding: 0 60px 0 90px !important;					
				}
				&:after{
					transform: rotate(-180deg);
					right: auto;
					left: 0;
				}
			}
		}
	}
	@include RWD(tablet){
		.pagin-first,
		.pagin-last{
			display: none !important;
		}
	}
	@include RWD(mobile){
		.pagin-item:not(.pagin-next):not(.pagin-prev){
			display: none;
		}
		.pagin-next{
			margin-left: auto;
		}
		.pagin-next, .pagin-prev{
			& > a, & > div{
				padding: 0 20px !important;
				&:after{
					display: none !important;
				}
				.btn-text{
					font-size: 0 !important;
				}
			}
		}
		.pagin-prev .btn-text:after{
			content:'Prec'; 
			font-size: 1.6rem;
		}
		.pagin-next .btn-text:before{
			content:'Suiv';
			font-size: 1.6rem;
		}
	}
}