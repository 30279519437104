%atom-sliders-dot--full{
    background-color: #fff;
    padding: 2px;
    border-radius: 50%;
    margin: 5px 7px;
    width: 10px;
    height: 10px;

    &.active{
        cursor: auto;
        opacity: 0.3;
    }
    &:not(.active){
        &:hover, &:focus{
            span{
                display: none;
                background-color: $core-color;
            }
        }
    }
}