%text-h1{
    color: $second-color;
    font-family: $site-font;
    font-size: 8rem;
    font-weight: 400;
    line-height: 1.2em;
    
    @include RWD(mobile){
        font-size: 4rem;
    }
}

%text-h2{
    color: $second-color;
    font-family: $site-font;
    font-size: 5rem; 
    font-weight: 400;
    line-height: 1.2em;

    @include RWD(mobile){
        font-size: 2.5rem;
    }
}
%text-h3{
    color: $core-color;
    font-family: $site-font;    
    font-weight: 400;
    font-size: 3.7rem;
    line-height: 1.2em;
    
    @include RWD(mobile){
        font-size: 2.5rem;
    }
}
%text-h4{
    color: $second-color;
    font-family: $main-font;    
    font-weight: 600;
    font-size: 3rem;
    line-height: 1.2em;

    @include RWD(mobile){
        font-size: 2rem;
    }
}
%text-h5{
    color: $grey-color-dark;
    font-size: 16px;
    font-size: 1.6rem;
    text-transform: uppercase;
}
%text-hat{
    font-family: $main-font;
    font-weight: 400;
    color: $text-color;
    font-size: 3rem;
    line-height: 1.2em;

    @include RWD(mobile){
        font-size: 2.0rem;
    }
}

//  HP
%text-section-title-hp{
    font-weight: 600;
    font-size: 32px;
    font-size: 3.2rem;
    letter-spacing: 0.05em;
}
%text-section-subtitle-hp{
    font-weight: 400;
    font-size: 16px;
    font-size: 1.6rem;
    text-transform: uppercase; 
    letter-spacing: 0.05em;
}

// TEASERS
%text-teaser-title{
    font-weight: 500;
    font-size: 20px;
    font-size: 2.0rem;
}
