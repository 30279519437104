%molecule-ariane{
    display: flex;
    flex-wrap: wrap;

    li, li>a, li>div{
        font-size: 1.5rem;
        color: #76787b;
        text-decoration: none;
        text-transform: uppercase;
    }
    li a{
        &:hover, &:focus{
            text-decoration: underline;
        }
    }
    li{
        display: flex;
        align-items: center;
        &:after{
            content: '';
            display: inline-block;
            width: 25px;
            height: 13px;
            background-size: 12px;
            background-position: center bottom;
            background-repeat: no-repeat;
            background-image: url( svg-bg-uri($svg-map-chevron, #76787b) );
        }
        &:last-child{
            &:after{
                display: none;
            }
            &> a,
            & > div{
                font-weight: 700;
                font-size: 1.4rem;
                color: #3a3c42;
            }
        }
        
        // &:first-child>a, 
        // &:first-child>div{
        //     color:#878787;
        // }
        // &:first-child+li>a,
        // &:first-child+li>div{
        //     color: #545454;
        // }
        
        // &:first-child+li+li>a,
        // &:first-child+li+li>div{
        //     color: #3b3b3b;
        // }
        
    }

    @include RWD(mobile){
        display: none;
    }
}