.front{
    background-color: #fff;

    .wrapper-home{
        display: flex;
        flex-wrap: wrap;
        position: relative;
    }

    // Shared style for home blocks
    .block-home{
        z-index: 10;
        
        .block-title{
            font-family: $site-font;
            font-size: 5rem;
            line-height: 5.7rem;
            color: $second-color;
        }
        .block-desc{
            font-size: 1.8rem;
            line-height: 3rem;
            color: $text-color;
        }
        .block-link{
            @extend %atom-btn-int--core;
        }

        @include RWD(mobile){
            .block-link{
                background: none !important;
                padding-left: 0 !important;
            }
        }
    }

    @extend %organism-carousel;

    @extend %organism-horaires;

    @extend %organism-presentation;

    @extend %organism-events;

    @extend %organism-agenda;

    @extend %organism-highlight;

    @extend %organism-gallery;

    @extend %organism-newsletter;


    @include RWD(desktop){
        // ANIMATIONS
        [data-scroll-animation='grid-delayed']{
            .delayed-element{
                opacity: 0;
                transform: translateY(25px);
                transition: all, 0.5s;
            }
            @for $i from 1 through 6{
                .delayed-element-#{$i}{
                    transition-delay: #{($i - 1) * 0.25}s;
                }
            }
            &[data-animation-state='active']{
                .delayed-element{
                    opacity: 1 !important;
                    transform: translateY(0);
                }
            }
        }

        [data-scroll-animation='grid-line-delayed']{
            .delayed-element{
                opacity: 0;
                transform: translateY(25px);
                transition: all, 0.5s;
            }
            .delayed-element-1,
            .delayed-element-2,
            .delayed-element-3{
                transition-delay: 0s;
            }
            .delayed-element-4,
            .delayed-element-5,
            .delayed-element-6{
                transition-delay: 0.5s;
            }
            &[data-animation-state='active']{
                .delayed-element{
                    opacity: 1 !important;
                    transform: translateY(0);
                }
            }
        }

        [data-scroll-animation='grid-fade']{
            .anim-element{
                opacity: 0;
                transform: translateY(5px);
                transition: all, 0.5s;
                transition-delay: 0.5s;
            }
            &[data-animation-state='active']{
                .anim-element{
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }

        [data-scroll-animation='zoom']{
            opacity: 0;
            transform: scale(0.8);
            transition: all, 0.5s;
            transition-delay: 0.5s;
            &[data-animation-state='active']{
                opacity: 1;
                transform: scale(1);
            }
        }

        [data-scroll-animation='grid-child']{
            & > *{
                opacity: 0;
                transform: translateY(25px);
                transition: all, 0.5s;

                @for $i from 1 through 4{
                    &:nth-child(#{$i}){
                        transition-delay: #{($i - 1) * 0.25}s;
                    }
                }                
            }

            
            &[data-animation-state='active'] > *{
                opacity: 1;
                transform: translateY(0);
            }
        }

        [data-scroll-animation='dezoom']{
            opacity: 0;
            transform: translateY(-50px) scale(2);
            transition: all, 0.5s;
            transition-delay: 0.5s;
            &[data-animation-state='active']{
                opacity: 1;
                transform: translateY(-50px) scale(1);
            }
        }

        [data-scroll-animation='slideup-delayed']{
            .delayed-element{
                opacity: 0;
                transform: translateY(100px);
                transition: all 0.5s ease-out;
            }
            @for $i from 1 through 3{
                .delayed-element-#{$i}{
                    transition-delay: #{($i - 1) * 0.4}s !important;
                }
            }
            &[data-animation-state='active']{
                .delayed-element{
                    opacity: 1 !important;
                    transform: translateY(0);
                }
            }        
        }

    }

}