$path: '../fonts';

// Ubuntu

    // Regular
    @font-face {
        font-family:'Ubuntu';
        src: url($path + '/Ubuntu-Regular.woff') format('woff');
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        unicode-range: U+0020-2074;
    }

    // Medium
    @font-face {
        font-family:'Ubuntu';
        src: url($path + '/Ubuntu-Medium.woff') format('woff');
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        unicode-range: U+0020-2074;
    }
    
    // Bold
    @font-face {
        font-family:'Ubuntu';
        src: url($path + '/Ubuntu-Bold.woff') format('woff');
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        unicode-range: U+0020-2074;
    }

// Varela Round

    @font-face {
        font-family:'Varela Round';
        src: url($path + '/VarelaRound-Regular.woff') format('woff');
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        unicode-range: U+0020-02DC;
    }