%bg-animation{
    transition: background 0.35s ease;    
}

%home-bloc-shadow{
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.3);
}


// LEGACY CHATOU VILLE - A SUPPRIMER
%animWidthUnderline{
    box-sizing: border-box;
    // animation-name: animWidthUnderline;
    // animation-timing-function: cubic-bezier(0.79, -0.04, 0.74, 0.05);
    // animation-duration: 0.35s;
    // animation-iteration-count: 1;
}
%animWidthUnderlineInfinite{
    box-sizing: border-box;
    // animation-name: animWidthUnderline;
    // animation-timing-function: cubic-bezier(0.79, -0.04, 0.74, 0.05);
    // animation-duration: 1.4s;
    // animation-iteration-count: infinite;
}

@keyframes animWidthUnderline {
    0%   {width: 0;}
    100% {width: 100%;}
}
