%organism-agenda{
    #agenda{
        width: 410px;
        padding: 0 40px 0 100px;
        position: relative;

        &:before{
            content: '';
            background: transparent url('../images/background/bg-middle.jpg') no-repeat 0 0;
            background-size: cover;
            width: 1171px;
            height: 754px;
            position: absolute;
            z-index: -1;
            left: 295px;
            top: 310px;
        }
        .block-title{
            margin-bottom: 22px;
        }
        .agenda-item{
            margin-bottom: 25px;
            display: block;
            text-decoration: none;
            position: relative;
            .date{
                color: $core-color;
                font-size: 2.8rem;
                line-height: 3.6rem;
                display: inline-block;
                position: relative;

                &:after{
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: -15px;
                    opacity: 0;
                    width: 100%;
                    height: 2px;
                    background-color: #B11C30;
                    transition: opacity 0.15s ease, bottom 0.35s ease;
                }
            }


            &:hover, &:focus{
                .date:after{
                    opacity: 1;
                    bottom: 0;
                }
            }
            
        }
        .block-link{
            background-image: url(svg-bg-uri($svg-calendar, $core-color));
            background-size: 28px 30px;
        }


        @include RWD(tablet){
            padding-left: 0;
            width: calc((100% - 40px) / 2);
            &:before{
                left: 235px;
                top: -60px;
                width: 951px;
                height: 613px;
            }
        }

        @include RWD(mobile){
            width: 100%;
            text-align: center;
            padding: 0;
            margin-bottom: 70px;

            &:before{
                content: none;
            }

            .block-link{
                margin-top: 15px;
            }
        }
    }
}