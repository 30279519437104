%organism-newsletter{
    #newsletter{
        height: 602px;
        width: 500px;
        padding: 185px 100px 0 0;
        margin-left: auto;
        text-align: right;
        position: relative;
        z-index: 9;
        
        .block-title{
            margin-bottom: 10px;
        }

        &:before{
            content: '';
            background: transparent url('../images/background/bg-bottom.jpg') no-repeat 0 0;
            background-size: cover;
            position: absolute;
            width: 1047px;
            height: 665px;
            z-index: -1;
            left: -1025px;
            top: -60px;
        }

        form{
            padding-top: 38px;
            input{
                height: 35px;
                width: 343px;
                line-height: 3.5rem;
                padding-left: 30px;
                background-image: url(svg-bg-uri($svg-contact, #b5b5b5));
                background-repeat: no-repeat;
                background-size: 18px 12px;
                background-position: 0 50%;
                border: none;
                border-bottom: 2px solid #bebfc2;
                margin-bottom: 18px;
            }

            button[type="submit"]{
                @extend %atom-btn-int--pill;
            }

            // Placeholders
            ::-webkit-input-placeholder{ color: #7a7c81;text-transform: uppercase; }
            :-moz-placeholder{ color: #7a7c81;text-transform: uppercase; }
            ::-moz-placeholder{ color: #7a7c81;text-transform: uppercase; }
            :-ms-input-placeholder { color: #7a7c81;text-transform: uppercase; }
        }


        @include RWD(tablet){
            height: auto;
            width: calc((100% - 40px) / 2);
            padding: 73px 0 0;
            margin-bottom: 360px;

            &:before{
                left: auto;
                top: auto; 
                right:130px;
                bottom: -360px;

                width: 786px;
                height: 507px;
            }
        }
        @include RWD(mobile){
            width: 100%;
            text-align: center;
            margin-bottom: 75px;

            &:before{
                content: none;
            }

            form input{
                max-width: 100%;
            }
        }
    }
}