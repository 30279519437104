html{
    font-size: 62.5%;
    font-family: $site-font;
    letter-spacing: 0.04em;

    body{
        background-color: #f5f5f5;
    }
    .body-container{
        position: relative;
        overflow-x: hidden;
        width: 100%;
    }

    &.no-scroll{
        height: 100%;
        overflow: hidden;
    }
}
#main-content{
    overflow: hidden;
}
.container{
    @extend %container;
}
// Selection de texte
::selection{
    background-color: rgba($color: $core-color, $alpha: 0.99);
    color: #fff;
}
::-moz-selection{
    background-color: $core-color;
    color: #fff;
}

// Placeholders
::-webkit-input-placeholder {
    opacity: 1 !important;
}
:-moz-placeholder{ 
    opacity: 1 !important;
} 
::-moz-placeholder { 
    opacity: 1 !important;
}
:-ms-input-placeholder {  
opacity: 1 !important;
}

.element-invisible, .hidden{
    display: none;
}