%molecule-rte-contact{
    background-color: #fff;
    width: 540px;
    max-width: 100%;
    line-height: 2.2rem;
    display: flex;
    box-shadow: 5px 8px 10px 0 rgba(0, 0, 0, 0.1);
    
    strong{
        display: block;
        font-weight: 600;
        text-transform: uppercase;
        color: $grey-color-dark;
    }

    p{
        font-size: 1.6rem;
        line-height: 2.2rem;
    }

    .left-content{
        max-width:52%;
        padding: 30px 0 0 30px;
    }
    .right-content{
        width: 48%;
        padding: 30px 30px 0;
    }

    .cblock{
        margin-bottom: 10px;
    }

    .contact-name{
        background-color: $second-color;
        height: 18px;
        line-height:18px;
        font-size: 2.2rem;
        font-weight: 600;
        color: $grey-color-dark;
        display: inline-block;
        padding-right: 3px;
        padding-left: 30px;
        margin-left: -30px;
    }
    .contact-job{
        &:first-line{
            font-style: italic;
        }
    }
    .contact-email{
        @extend %atom-btn-int--inverted;
        height: 60px;
        width: 100%;
        margin-bottom: 20px;
    }

    //.contact-address{}
    // .contact-telephone{}
    //.contact-horaires{}

    @include RWD(mobile){
        flex-wrap: wrap;

        .left-content, .right-content{
            max-width: 100%; 
            width: 100%;
        }
    }
}

%molecule-rte-crossreading{
    width: 100%;

    ul{
        display: flex;
        justify-content: space-between;
        padding: 0;

        li{
            width: calc((100% - 60px) / 3);
            background-size: cover;
            &:before{
                content: none;
            }

            a{
                padding-top: 63.3%;
                position:relative;
                background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
                background-size: 100% 200%;
                background-position: 0 0;
                transition: all 0.35s ease;
                display: block;

                &:hover, &:focus{
                    background-size: 100% 100%;
                }

                .wrapper{
                    display: block;
                    position: absolute;
                    left: 0;
                    bottom: 33px;
                    width: 100%;
                    padding: 0 30px;
                    text-align: center;
                    text-decoration: none;
                    height: 82px;

                    h4{
                        background: none;
                        color: #FFF;
                        font-size: 2.7rem;
                        line-height: 2.7rem;
                        font-weight: 600;
                        display:block;
                        margin: 0;
                        padding: 0;
                        text-transform: uppercase;
                        transition: transform 0.35s ease;

                        &:first-line{
                            font-weight: 400;
                        }
                    }
                }

                &:hover, &:focus{
                    .wrapper h4{
                        transform: translateY(-10px);
                    }
                }
            }
        }
    }

    @include RWD(tablet){
        ul li a .wrapper h4{
            font-size: 2rem;
            line-height: 2.4rem;
        }
    }

    @include RWD(mobile){
        ul{
            flex-wrap: wrap;
            li{
                width: 100%;
            }
        }
    }   

}


%molecule-rte-faq{
    margin-bottom: 20px;
    overflow: hidden;

    .faq-item{
        width: 100%;
        margin-bottom: 30px;
        box-shadow: 5px 8px 10px 0 rgba(0, 0, 0, 0.1);

        .faq-question{
            position:relative;
            font-size: 3rem;
            font-weight: 500;
            line-height: 4.6rem;
            color: $grey-color-light;
            padding: 10px 110px 15px 40px;
            background-color: #fff;
            border-bottom: 10px solid $core-color;
            cursor: pointer;

            &:after{
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                width: 110px;
                height: 100%;
                background-image: url(svg-bg-uri($svg-arrow-line, $grey-color-light));
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: 32px 17px;
                transition: all 0.35s ease;
            }
        }
        .faq-answer{
            padding: 15px 40px 10px;
            font-size: 1.6rem;
            line-height: 2.2rem;
            color: $grey-color-dark;
            background-color: $core-color;

            p{
                margin-bottom: 20px;
            }
        }

        &:hover, &:focus{
            .faq-question:after{
                background-image: url(svg-bg-uri($svg-arrow-line, $core-color));
            }
        }

        &.active{
            .faq-question:after{
                background-image: url(svg-bg-uri($svg-arrow-line, $core-color));
                transform: rotateX(180deg);
            }
        }
    }

    @include RWD(mobile){
        .faq-item{
            .faq-question{
                font-size: 2.6rem;
                line-height: 3rem;
                padding: 10px 60px 15px 20px;
                &:after{
                    width: 60px;
                }
        } 
        .faq-answer{
            padding-left: 20px;
            padding-right: 20px;
        }
        }
    }

}

%molecule-rte-iframe{
    iframe{
        width: 100%;
        min-height: 300px;
    }
}