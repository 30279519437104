%molecule-media{
    .media-body{
        padding: 20px;
        background-color: #fcfcfc;
    }
    .media-content{
        display: block;
    }
    .media-ratio{
        margin-bottom: 40px;
        width: 100%;
    }
    .media-actions{
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
    .media-heading{
        padding-left: 0;
        padding-top: 10px;
        // Extend text media heading
    }
    .media-description{
        @extend %text-basis-rte;
        & > *:last-child{
            margin-bottom: 0;
        }
        padding: 0 20px;
    }

    .media-transcription-button{
        @extend %atom-btn-int--core;
        @include RWD(mobile){
            padding: 0 10px;
            height: unset;
            &:after{
                display: none !important;
            }
        }
    }
    .media-transcription{
        @extend %molecule-transcription-general;
    }
}