%organism-events{
    #events{
        width: 100%;
        margin-bottom: 140px;
        .events-list{
            display: flex;
            justify-content: space-between;
        }
        .event{
            width: calc((100% - 60px) / 3);
            background-color: $core-color;
            
            .visu{
                background-size: cover;
                background-position: 50%;
                background-repeat: no-repeat;
                padding-top:75%; 
            }

            .content{
                padding: 60px 40px 50px 40px;
                background-color: $second-color;
                height: 325px;
                
                
                .block-title{
                    font-size: 3rem;
                    color: #fff;
                    margin-bottom: 10px;
                    line-height: 3.6rem;
                }
                .block-desc{
                    font-size: 1.8rem;
                    color: #adaeb0;
                    margin-bottom: 40px;
                }
                .block-link{
                    @extend %atom-btn-int--inverted;
                    font-size: 1.8rem;
                    padding-left: 0;
                }
            }
        }


        @include RWD(tablet_only){
            margin-bottom: 60px;
            .events-list{
                flex-wrap: wrap;
            }
            .event{
                width: calc((100% - 40px) / 2 );
                &:first-child{
                    display: flex;
                    width: 100%;
                    margin-bottom: 40px;
                    .visu{
                        width: 50%;
                        padding-top:0;
                    }
                    .content{
                        width: 50%;
                    }
                }
            }
        }

        @include RWD(mobile){
            margin-bottom: 70px;
            .events-list{
                flex-wrap: wrap;
            }
            .event{
                width: 100%;
                margin-bottom: 20px;

                .content{
                    padding: 35px 20px 40px;
                }
            }
        }
    }
}