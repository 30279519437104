%organism-gallery{
    #gallery{
        width: 410px;
        padding: 370px 100px 0 0;
        text-align: right;
        .block-title{
            margin-bottom: 40px;
        }
        .block-link{
            background-image: url(svg-bg-uri($svg-image, $core-color));
            background-size: 23px 23px;
            background-position: 0 center;
        }

        @include RWD(tablet){
            width: calc((100% - 40px) / 2);
            padding: 73px 0 0;
        }
        @include RWD(mobile){
            width: 100%;
            padding-top: 0;

            h2{
                font-size: 4rem;
                line-height: 4.8rem;
            }
        }
    }
}