%organism-horaires{
    #horaires{
        z-index: 10;
        .content{
            z-index: 1;
            position: absolute;
            right: 0;
            transform: translate(0, -150px);
            width: 410px;
            padding: 42px 45px;
            font-family: $main-font;
            color: #fff;
            background-color: $core-color;
        }
        &:before{
            content: url('../images/background/bg-map.jpg');
            position: absolute;
            z-index: 0;
            left: 540px;
            top: 95px;
            // background-image: url("../images/background/bg-map.jpg");
            // content: '';
            background-size: 974px 731px;
            right: 0;
            height: 731px;
        }

        .hor-top{
            height: 109px;

            padding-bottom: 45px;
            border-bottom: 2px solid #fa2461;

            .opening-status{
                position: relative;
                display: inline-block;
                min-height: 30px;
                padding-left: 50px;
                padding-right: 20px;
                margin-bottom: 10px;
                font-size: 1.5rem;
                line-height: 25px;
                border-radius: 15px;
                border: 1px solid #ff608d;

                &:before{
                    content: '●';
                    font-size: 28px;
                    line-height: 2rem;
                    position: absolute;
                    left: 0;
                    top: 0;
                    padding-left: 20px;

                }
                &.open:before{
                    color: $success;
                }
            }
            .horaires{
                margin-bottom:5px; 
                padding-left: 5px;
                font-size: 2rem;
                line-height: 2.8rem;
                strong{
                    font-weight: 600;
                }
            }
        }

        .hor-bottom{
            padding-top: 50px; 
            &>div{
                background-repeat: no-repeat;
                background-position: 0 0;
                padding-left: 44px;
                font-size: 2rem;
                line-height: 2.4rem;
            }
            .address{
                margin-bottom: 26px;
                background-image: url(svg-bg-uri($svg-marker, #ff608d));
                background-size: 15px 21px;
            }
            .phone{
                font-weight: 600;
                background-image: url(svg-bg-uri($svg-tel, #ff608d));
                background-size: 20px 20px;
            }
        }


        @include RWD(tablet){
            .content{
                position: relative;
                width: 275px;
                top: 0;
                transform: translate(0, -30px);
                padding: 42px 30px;
                margin-bottom: 45px;

                .hor-top{
                    height:auto;
                    padding-bottom: 30px;
                }
            }
            &:before{
                left: 106px;
                top: 0;
            }
        }
        @include RWD(mobile){
            .content{
                transform: none;
                width: 100%;
                margin-bottom: 325px;
            }
            &:before{
                left: -270px;
                top: 270px;
            }
        }
    }
}