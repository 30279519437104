/**
Page formulaire composée de :
    - Un container spécial
    - Une structure de formulaire
    - Le design des champs
    - Un skin de validation
    - Une preview
    - Une progress Bar
    - Confirmation d'envoie
    - Messages d'erreur BO
*/

.not-front{
    .webform-client-form{
        @extend %container-form;
        // Structure/Distribution
        @extend %bone-form-structure; 
        // Design des champs
        @extend %molecule-form-components;
        // Validation
        @extend %skin-form-validation; 
        clear: both;
        margin-bottom: $margin-item-rte;

        // Prévisualisation
        &.preview{
            @extend %molecule-form-preview;
        }
    }
    .generic-form{
        // Structure/Distribution
        @extend %bone-form-structure;
        // Design des champs
        @extend %molecule-form-components;
        // Validation
        @extend %skin-form-validation; 
    }
    
    // Progress Bar étapes
    .webform-progressbar{
        @extend %molecule-nav-progressbar;
    }
    // Confirmation d'envoi
    .webform-confirmation{
        @extend %molecule-form-confirmation;
    }
    // Messages d'erreurs BO
    .messages.error, .messages.warning{
        @extend %molecule-form-warning;
        margin-bottom: 20px;
    }
    // Collage des warning au bord de l'écran lorsqu'il est dans un container /!\ c'est très approximatif en l'état, vaut mieux le laisser dans le container
    .container{
        .messages.error, .messages.warning{
            @include RWD(desktop){
                // margin-left: -120px;
                // margin-right: -120px;                    
            }
        }
    }
}