%molecule-share-this{
    display: flex;
    align-items: center;

    .share-item{
        margin-right: 10px;
        &:last-child{
            margin-right: 0;
        }
        a{
            font-size: 0;
            text-decoration: none;
            display: block;
            &:before{
                font-size: 20px;
                font-size: 2.0rem;
                color: $second-color;
                transition: all, 0.5s;
                font-family: 'FontAwesome';
            }
            &:hover, &:focus{
                &:before{
                    color: $core-color;
                } 
            }
        }

        &.share-facebook{
            a:before{
                content: '\f09a';
            }
        }
        &.share-twitter{
            a:before{
                content: '\f099';
            }
        }
        &.share-googleplus{
            a:before{
                content: '\f0d5';
            }
        }

    }

}