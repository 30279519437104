%molecule-transcription-general{
    padding: 40px;
    // background-color: #f7f7f7;
    display: none;
    width: 100%;

    > *:last-child{
        margin-bottom: 0;
    }

    @include RWD(tablet){
        padding: 20px;
    }
}