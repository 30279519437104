%organism-header{
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 130px;

    &:after{
        content: '';
        background: transparent url('../images/etiquette.png') 0 0 no-repeat;
        background-size: cover;
        width: 379px;
        height: 173px;
        position: absolute;
        bottom: -130px;
        right: -120px;
        z-index: 10;
    }

    #logo{
        padding-top: 30px;
        h1, a{
            display: block;
            text-decoration: none;
            span:nth-child(1){
                display: block;
                font-size: 2.5rem;
                font-weight: 500;
                line-height: 1.8rem;
                margin-bottom:15px;
                text-transform: uppercase;
                color: $grey-color-mild;
            }
            span:nth-child(2){
                display: block;
                font-size: 10rem;
                font-weight: 600;
                line-height: 7.5rem;
                color: $grey-color-dark;
            }
        }
    }
    .header-right{
        position: relative;
        .translate{
            display: inline-block;
            position: absolute;
            right: 0;
            top: 20px;
            height: 26px;
            line-height: 26px;
            color: #fff;
            background-color: $core-color;
            border-radius: 13px;
            z-index: 10;
            overflow: hidden;
            z-index: 100;

            &:hover, &:focus{
                overflow: visible;
            }
            
            .selected{
                padding: 0 18px 0 23px;
                opacity: 1;
                font-size: 1.4rem;
                display:block;
                font-family: $main-font;
                font-weight: 700;
                text-transform: uppercase;
                position: relative;
                z-index: 2;

                &:after{
                    content: '▼';
                    display: inline-block;
                    margin-left: 9px;
                    color: #fff;
                }
            }

            &.opened{
                overflow: visible;
            }
        }
        .dropdown{
            padding: 10px 18px 5px 23px;
            background-color: $core-color;
            font-size: 1.4rem;
            display:block;
            font-family: $main-font;
            font-weight: 700;
            text-transform: uppercase;
            border-bottom-right-radius: 13px;
            border-bottom-left-radius: 13px;
            transform: translateY(-10px);
            li{
                text-align: left;
                line-height: 1.8em;
            }
            a{
                color: #FFFFFF;
                text-decoration: none;
                &:hover, &:focus{
                    text-decoration: underline;
                }
            }
        }
          
        nav#main-menu{
            display: flex;
            justify-content: flex-end;
            padding-top: 56px;
            ul{
                display: flex;
                li{
                    margin: 0 10px;
                    &:first-child{margin-left: 0;}
                    &:last-child{margin-right: 0;}
                    a{
                        display: block;
                        position: relative;
                        text-decoration: none;
                        .btn-text{
                            font-weight: 500;
                            color: #757575;
                            font-family: $main-font;
                            font-size: 1.6rem;
                            transition: color 0.2s ease;
                           
                        }
                        &.contact{
                            display: inline-block;
                            width: 21px;
                            height: 21px;
                            background-image: url(svg-bg-uri($svg-contact-header, #757575));
                            background-repeat: no-repeat;
                            background-position: 1px 50%;
                            background-size: 19px 13px;
                            transition: background 0.35s ease;
                            &:hover, &:focus{
                                background-image: url(svg-bg-uri($svg-contact-header, $core-color));
                            }
                        }
                        
                        &:after{
                            content: '';
                            display: block;
                            position: absolute;
                            bottom: -15px;
                            opacity: 0;
                            width: 100%;
                            height: 2px;
                            background-color: $core-color;
                            transition: opacity 0.15s ease, bottom 0.35s ease;
                        }
                        &:hover, &:focus{
                            .btn-text{
                                color: $core-color;
                            }
                            &:after{
                                opacity: 1;
                                bottom: -5px;

                            }
                        }
                    }
                }
            }
        }
    }


    @include RWD(tablet){
        position: relative;
        overflow: visible;
        height: 112px;

        &:after{
            bottom: -145px;
            right: 0px;
            background-position: right -40px center;
        }

        #logo{
            position: absolute;
            padding-top: 0;
            top: 50%;
            left: 40px;
            transform: translateY(-50%);

            h1, a{
                span:nth-child(1){
                    font-size: 2rem;
                    margin-bottom:0;
                }
                span:nth-child(2){
                    font-size: 8.1rem;
                }
            }
        }
        .header-right{
            width: 100%;

            .translate{
                right: 70px;
                top: 50%;
                transform: translateY(-50%);
            }
            nav#main-menu{
                display: none;
            }
        }    

        #rwdMenuToggle{
            display: block;
            height: 50px;
            width: 50px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            .picto{
                position: relative;
                height: 3px;
                width: 38px;
                display: block;
                margin: 0 auto;
                background-color: $second-color;
                border-radius: 3px;
                &:before, 
                &:after{
                    content: '';
                    position: absolute;
                    display: block;
                    height: 3px;
                    width: 38px;
                    background-color: $second-color;
                    border-radius: 3px;
                }
                &:before{
                    top: -9px;
                } 
                &:after{
                    bottom: -9px;
                }
            }
        }


    }
    @include RWD(mobile){

        padding: 0;
        background-color: #fff;
        height: 150px;
        z-index:  11;

        &:after{
            width: 200px;
            height: 89px;
            bottom: -60px;
            right: 0px;
            background-position: right -30px center;
        }

        #logo{ 
            left: 70px;
            img{
                min-width: 215px;
                max-width: 90%;
            }
        }
        .header-right{
            width: 70px;
            .translate{
                width: 94px;
                top: 100%;
                left: 20px;
                right: auto;
            }
        }

        #rwdMenuToggle{
            right: auto;
            left: 10px;
        }
    }

}


%organism-menu-rwd{
    position: fixed;
    height: 100%;
    width: 100%;
    top:0;
    left: 0;
    z-index: 101;
    background-color: rgba(0,0,0, 0.85);
    
    .close{
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 40px;
        background-image: url(svg-bg-uri($svg-close, #fff));
        background-size: 30px 30px;
        background-position: 50%;
        background-repeat: no-repeat;
    }
    
    .content{
        display: flex;
        justify-content: center;
        height: 100%;
        flex-direction: column;
        align-items: left;

        & > *{
            padding: 40px;
            position: relative;
            &:after{
                content:'';
                width: 50vw;
                position: absolute;
                bottom: 0;
                left: 15px;
                height: 1px;
                background-color: #fff;
            }

            &:last-child{
                &:after{
                    content: none;
                }
            }
        }


        li{
            a{
                font-size: 3rem;
                color: #fff;
                padding: 15px 0;
                display: inline-block;
                text-decoration: none;
                text-transform: uppercase;
                text-align: center;

                @include RWD(mobile){
                    font-size: 1.8rem;
                }
                .btn-text{
                    text-align: left;
                }
    
                &:after{
                    content: '';
                    display: block;
                    position: relative;
                    top: 0;
                    height: 2px;
                    width: 0;
                    background-color: #fff;
                }
    
                &:hover, &:focus{
                    color: #fff;
                    &:after{
                        @extend %animWidthUnderline;
                        width: 100%;
                    }
                }
            }
        }


        .translate-select{
            position: relative;
            & > a{
                display: block;
                height: 60px;
                padding: 17px 20px 16px 45px;
                font-size: 3rem;
                text-transform: uppercase;
                color: #fff;
                text-decoration: none;
                line-height: 2rem;
                background-position: 5px 43%;
                background-repeat: no-repeat;
                background-image: url(svg-bg-uri($svg-flag, #fff));
                background-size: 20px;
                &:after{
                    content: '▼';
                    display: inline-block;
                    margin-left: 10px;
                    position: relative;
                    top: -5px;
                    font-size: 1.6rem;
                    transition: all 0.35s ease;
                }
            }
            ul{
                display: none;
                a{
                    padding: 0 0 15px 40px;
                }
            }
            &.opened{
                & > a:after{
                    transform: rotateZ(-90deg);
                }
            }
        }


        .nav-profil{
            
            display: flex;
            flex-direction: column;

            button{
                display: inline-flex;
                padding: 17px 20px 16px 40px;
                background-image: url(svg-bg-uri($svg-people, #fff));
                background-position: 5px 53%;
                background-size: 19px;
                background-repeat: no-repeat;
                position: relative;
                height: 60px;
                
                text-transform: uppercase;
                line-height: 3rem;
                font-size: 3rem;
                color: #fff;

                &:after{
                    content: '▼';
                    display: inline-block;
                    margin-left: 10px;
                    position: relative;
                    top: -5px;
                    font-size: 1.6rem;
                    transition: all 0.35s ease;
                }
            }

            .nav-profil-dropdown{
                display: none;
                overflow: hidden;
                padding-left: 40px;
                a{
                    text-align: left;
                }
            }

            &.opened{
                button:after{
                    transform: rotateZ(-90deg);
                }
            }
            
        }


    }

    @include RWD(tablet){
        .close{
            top: 30px;
            right: 52px;
            width: 60px;
            height: 50px;
            background-size: 50px;
        }
    }
    @include RWD(mobile){
        .close{
            top: 10px;
            right: 10px;
            width: 30px;
            height: 30px;
            background-size: 30px;
        }
    }
}