%organism-presentation{
    #presentation{
        // Width depends on #horaires width
        width: calc(100% - 410px);

        padding: 138px 50px 120px 100px;
        font-family: $main-font;
        
        .block-title{
            margin-bottom: 25px;
        }
        .block-desc{
            margin-bottom: 45px;
        }
        .block-link{
            background-image: url(svg-bg-uri($svg-download, $core-color));
            background-size: 23px 30px;
        }


        // Media queries
        @include RWD(tablet){
            width: 100%;
            padding: 0;
            margin-bottom: 65px;
        }
    
        @include RWD(mobile){
            
        }
    }


}