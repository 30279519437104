%organism-footer{
    display: block;
    background-color: #fff;
    position: relative;
    z-index: 11;

    #block_Partners{
        background-color: #b5b5b5;
        padding: 40px 0;
        ul{
            @extend %container;
            display: flex;
            height: 76px;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;
            li a{
                display:inline-block;
                position: relative;

                img{
                    transition: opacity 0.35s ease; 
                    &:not(.hover){
                        opacity:1;
                    }
                    &.hover{
                        position:absolute;
                        opacity:0;
                        top: 0;
                        left: 0;
                    }
                }
                &:hover, &:focus{
                    img{
                        &:not(.hover){
                            opacity:0;
                        }
                        &.hover{
                            opacity:1;
                        }
                    }
                };
            }
        }
        @include RWD(tablet){
            padding: 40px 0 20px;
            ul{
                height: auto;
                li{
                    margin: 0 0 20px;
                    width: 25%;
                    text-align: center;
                }
            }
        }
        @include RWD(mobile){
            padding: 20px 0 0;
            margin-bottom: 60px;
            ul{
                margin: 0;
                padding: 0 20px;
                width: 100%;

                li{
                    width: 50%;
                }
            }
        }
    }

    .footer-top-links{
        padding-top: 70px;
        padding-bottom:80px;
        & > ul{
            @extend %container;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            
            font-size: 1.6rem;
            color: $text-color;
            
            .title{
                margin-bottom: 30px;
                color: $second-color;
                font-weight: 600;
                text-transform: uppercase;
            }

            & > li{

                &.logo-footer{
                    width: 32.75%;
                }
    
                &.contact-footer,
                &.opening-footer{
                    width: 23.65%;
                }   
    
                &.social-footer{
                    width: 19.90%;
                    text-align: center;
    
                }

                p{
                    line-height: 2.4rem;
                }
            }
            


            .logo-footer-top,
            .logo-footer-bottom{
                display: block;
                max-width: 100%;
            }
            .logo-footer-top{
                margin-bottom: 30px;
                margin-top: -6px;
            }
            .logo-footer-bottom{
                width: 175px;
                height: 66px;
            }
            
            .tripadvisor{
                margin-top: -20px;
                margin-bottom: 10px;
                display: inline-block;
            }
            .facebook,
            .youtube{
                display: inline-block;
                width: 24px;
                height: 24px;
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: 24px;
            }
            
            .facebook{
                background-image: url(svg-bg-uri($svg-facebook, #737578));
                &:hover, &:focus{
                    background-image: url(svg-bg-uri($svg-facebook, $core-color));
                };
            }
            .youtube{
                background-image: url(svg-bg-uri($svg-youtube, #737578));
                &:hover, &:focus{
                    background-image: url(svg-bg-uri($svg-youtube, $core-color));
                };
            }

        }

        @include RWD(tablet){
            & > ul{
                .logo-footer-top,
                .logo-footer-bottom{
                    margin: 0 auto;
                }
                .logo-footer-top{
                    margin-bottom: 30px;
                }
                
                & > li{
                    width: calc(33.3333332% - 10px);
                    text-align: center;
                    &:first-child{
                        width: 100%;
                        text-align: center;
                        margin-bottom: 60px;
                    }
                }
            }
        }
        @include RWD(mobile){
            padding: 0;
            margin: 0 20px 10px;

            & > ul{
                .logo-footer-top{
                    margin-bottom: 30px;
                    max-width: 100%;
                }
                & > li{
                    width: 100%;
                    text-align: center;
                    flex: none;
                    margin-bottom: 35px;
                }
    
                ul li a.social-facebook,
                ul li a.social-twitter{
                    display: inline-block;
                }
            } 
        }
    }

    .footer-bottom-links{
        @extend %container;
        ul{
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top: 1px solid #d5d5d5;
        
            li a{
                display: inline-block;
                color: #737578;
                font-size: 1.4rem;
                text-decoration: none;
                //text-transform: uppercase;
                margin-right: 40px;
                transition: color 0.35s ease;   

                &:hover, &:focus{
                    color: $core-color;
                }
            }
            li a:last-child{
                margin-right: 0;
            }
        }

        @include RWD(tablet){
            
        }
        @include RWD(mobile){
            padding-bottom: 10px;
            ul{
                flex-wrap: wrap;
                padding-top: 8px;
                height: auto;
                li{
                    width: 100%;
                    text-align: center;
                    line-height: 2.5rem;
                    a {
                        margin: 0 10px;
                        line-height: 36px;
                    }
                }
            }
        }
    }
}