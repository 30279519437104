%atom-hat-default{
    @extend %text-hat;
    text-align: center;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    max-width: 100vw;
    margin-bottom: 30px;
    line-height: normal;
    
    .container{
        @extend %container;
    }
    strong{
        font-weight: 600;
    }
    em{
        font-style: italic;
    }
}