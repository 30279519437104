%text-basis-rte{    
    font-family: $main-font;
    font-size: 1.8rem;
    color: $text-color;
    font-weight: 400;
    line-height: 3rem;
}

%text-caption-rte{
    color: #adaeb0;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2.2rem;
}

%text-teaser-description{
    font-weight: 400;
    //font-size: 15px;
    font-size: 1.5rem;
    line-height: normal;
}