// BreakPoints
$break-small: 767;
$break-large: 1280; // Cette valeur doit être égale à la largeur du container + X pixel. Le passage en tablette se fera dont à X pixel de plus que la largeur du container principal, permettant de ne jamais avoir le contenu collé au bord de l'écran.
$container-width: 1200;

//~#COLORS
	$core-color: #B11C30;
	$core-color-access: $core-color;
	$core-color-light: #ff608d;
	
	$second-color: #3A3C42;
	$second-color-light: lighten($second-color,65);

	
	$success: #3BAA64;
	$error: #B11C30;
	
	$text-color: #737578;
	
	$background-color: #FFFFFF;
	
	// LEGACY CHATOU VILLE - A SUPPRIMER
	$toDelete: #ff17b9;
	$accent-color: #fcc244;
	$accent-color-light: #ffffff;
	$grey-color-dark: #231f20;
	$grey-color : #2f2f2f;
	$grey-color-light : #797979;
	$grey-color-mild : #4c4c4c;


//#COLORS

// Fonts
$site-font: 'Varela Round';
$main-font: 'Ubuntu'; 

/*IMAGES RTE*/
$image-xs: 240px;
$image-sm: 480px;
$image-md: 620px;

// Margin RTE
$margin-item-rte: 40px;
$margin-p: 20px;

$margin-h2: 25px;
$margin-h3: 25px;
$margin-h4: 20px;
$margin-h5: 15px;

$margin-h2-mobile: 20px;
$margin-h3-mobile: 20px;
$margin-h4-mobile: 15px;
$margin-h5-mobile: 10px;

// Map engine
$icon-color: #595959;
$icon-color-active: $second-color;