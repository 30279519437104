%organism-carousel{
    #carousel{
        width: 100%;
        height: 620px;
        background-color: #ccc;


        @include RWD(tablet){
            height: 525px;
        }
        @include RWD(mobile){
            height: 365px;
            margin-bottom: 20px;
        }
    }
}