%atom-btn-int{ 
    position: relative;
    display: inline-block;
    height: 30px;
    padding: 0 70px 0 40px;
    cursor: pointer;
    font-family: $main-font;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 3rem;
    color: $second-color;
    text-transform: uppercase;
    text-decoration: none;
    background-position: 0 0;
    background-repeat: no-repeat;
    transition: color 0.35s ease;

    &:after{
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width:70px;
        height: 30px;
        background-image: url(svg-bg-uri($svg-fleche, #bebfc2));
        background-repeat: no-repeat;
        background-position: 20px 50%;
        background-size: 38px 19px;
        transition: all 0.35s ease;
    }

    &:hover, &:focus{
        &:after{
            background-position: 30px 50%;
        }
    }

    &--core{
        @extend %atom-btn-int;
        &:hover, &:focus{
            color: $core-color;
        }
    }

    &--inverted{
        @extend %atom-btn-int;
        color: #fff;
        &:hover, &:focus{
            color: $core-color;
        }
    }

    &--pill{
        @extend %atom-btn-int;
        height: 52px;
        padding: 0 90px 0 60px;
        font-size: 1.5rem;
        line-height: 5.2rem;
        color: #fff;
        background-color: $core-color;
        border-radius: 26px;
        transition: background 0.35s ease;
        &:after{
            width: 90px;
            height: 52px;
            background-image: url(svg-bg-uri($svg-fleche, $core-color-light));
            background-position: 12px 47%;
            background-size: 38px 19px;
        }
        &:hover, &:focus{
            color: #fff;
            background-color: $second-color;
            &:after{
                background-position: 22px 47%;
                background-image: url(svg-bg-uri($svg-fleche, $second-color-light));
            }
        }
    }

    &--disabled{
        @extend %atom-btn-int;
        &:after{
            background-image: url(svg-bg-uri($svg-fleche, #bebfc2));
        }
        &:hover, &:focus{
            color: $core-color;
        }
    }
}


//~#BUTTONS
.btn-int--core{
    @extend %atom-btn-int--core;
}

.btn-int--inverted{
    @extend %atom-btn-int--inverted;
}

.btn-int--disabled{
    @extend %atom-btn-int--disabled;
}

.btn-int--pill{
    @extend %atom-btn-int--pill;
}
//#BUTTONS