
%organism-highlight{
    // Shared styles for highlight blocks
    .block-highlight{
        position: relative;
        display: flex;
        width: calc(100% - 410px);
        padding-bottom: 56px;

        .highlight-visu{
            z-index: 1;
            width: 380px;
            height: 475px;
            background-color: $core-color;
            box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.55);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50%;
            
        }
        .highlight-content{
            z-index: 2;
            width: 410px;
            height: 490px;
            padding: 120px 40px 50px;
            background-color: #f2f2f2;
            box-shadow: 0 0 57px 0 rgba(0, 0, 0, 0.2);

            .block-title{
                margin-bottom: 10px;
                line-height: 6rem;
            }
            .block-desc{
                margin-bottom: 40px;
                color: #686a6e;
            }
            .block-link{
                background: none;
                padding-left: 0;
            }
        }
    }

    #highlight1{
        margin-bottom: 140px;
        .highlight-visu{
            transform: translate(70px, 70px);
        }
    }

    #highlight2{
        text-align: right;
        .highlight-visu{
            transform: translate(-70px, 70px);
        }
    }

    @include RWD(tablet){
        .block-highlight{
            width: 100%;
            display: flex;
            justify-content: center;
        }
        #highlight1{
            margin-bottom: 65px;
            .highlight-visu{
                transform: translate(35px, 70px);
            }
            .highlight-content{
                transform: translateX(-35px);
            }
        }
        #highlight2{
            text-align: right;
            .highlight-visu{
                transform: translate(-35px, 70px);
            }
            .highlight-content{
                transform: translateX(35px);
            }
        }
    }

    @include RWD(mobile){
        .block-highlight{
            width: 100%;
            display: block;
            padding-top: 180px;

            .highlight-visu{
                width: 100%;
                position: absolute;
                transform: none !important;
                z-index: -1;
                top: 0;
            }
            .highlight-content{
                padding: 40px 20px;
                width: 100%;
                height: auto;
                transform: none !important;

                .block-title{
                    font-size: 4rem;
                    line-height: 4.6rem;
                }
            }
        }

        #highlight1{
            padding-bottom: 0;
            margin-bottom: 75px;
            .highlight-visu{
                left: -20px;
            }
        }
        #highlight2{
            padding-bottom: 0;
            margin-bottom: 70px;
            .highlight-visu{
                left: 20px;
            }
        }
    }
}